import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { catchError, delay, firstValueFrom, map, of, timeout } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { UserControllerService, UserPrincipal } from "src/app/generated-client/labmouse-api-rest-client";
import { User } from "src/app/models/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  env = environment;
  constructor(
    private http: HttpClient,
    private userControllerService: UserControllerService
  ) {}

  userSig  = signal<User[]>([]);
  adminSig = signal<User[]>([]);

  // getCurrentUser() {
  //   return this.userControllerService.getCurrentUser().pipe(delay(5000), timeout(3000));
  // }


   //GET ALL USERS
   async getAllUsers(): Promise<User[]> {
    const user$ = this.http.get(`${this.env.apiUrl}/users`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return of(err);
      }))
      const response = await firstValueFrom(user$);
      return response;
  }

   //GET Single USER
   async getSingleUser(userId: string): Promise<User> {
    const user$ = this.http.get(`${this.env.apiUrl}/users/${userId}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return of(err);
      }))
      const response = await firstValueFrom(user$);
      return response;
  }

   //GET Me
   async getMe(): Promise<User> {
    const user$ = this.http.get(`${this.env.apiUrl}/users/me`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return of(err);
      }))
      const response = await firstValueFrom(user$);
      return response;
  }

  //Create User
  async createUser(user: User): Promise<User> {
    const body = user;
    const user$ = this.http.post(`${this.env.apiUrl}/users`, body).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return of(err);
      }))
      const response = await firstValueFrom(user$);
      return response;
  }

  //Update User
  async updateUser(user: User, userId: string): Promise<User> {
    const body = {user};
    const user$ = this.http.put(`${this.env.apiUrl}/users/${userId}`, body).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return of(err);
      }))
      const response = await firstValueFrom(user$);
      return response;
  }

  //Delete User
  async deleteUser(user: User, userId: string): Promise<User> {
    const user$ = this.http.delete(`${this.env.apiUrl}/users/${userId}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return of(err);
      }))
      const response = await firstValueFrom(user$);
      return response;
  }

  //Make User Admin
  async makeAdmin(user: User, userId: string): Promise<User> {
    const body = {user};
    const user$ = this.http.put(`${this.env.apiUrl}/users/${userId}/giveAdmin`, body).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return of(err);
      }))
      const response = await firstValueFrom(user$);
      return response;
  }

  //Make Take Admin
  async takeAdmin(user: User, userId: string): Promise<User> {
    const body = {user};
    const user$ = this.http.put(`${this.env.apiUrl}/users/${userId}/takeAdmin`, body).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return of(err);
      }))
      const response = await firstValueFrom(user$);
      return response;
  }
}
