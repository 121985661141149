import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, delay, map } from 'rxjs';
import { UsersService } from '../../users/users.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthSsoService {
  env = environment;

  constructor(
    private http: HttpClient,
    private userService: UsersService
  ) //private authService: AuthService
  {}

  validateUser() {
    const url = environment.apiUrl + `/users/me`;
    //return this.userService.getCurrentUser().pipe(delay(20000));
    // const headers = new HttpHeaders();
    // headers.append('Content-Type', 'application/json');
    // headers.set('Authorization', `Bearer ${token}`);
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   Authorization: `Bearer ${token}`,
    // });

    // console.log('headers', headers);
    return this.http.get(url).pipe(
      map((res: any) => {
        //const headers = res.headers;
       // const cookies = document.cookie;
        // Example: Log all headers
        // headers.keys().forEach((key) => {
        //   console.log(`${key}: ${headers.get(key)}`);
        // });

       // console.log('validateUser', res, res.body, headers, cookies);
       // console.log('validateUser: cookies', cookies);
        return res;
      })
    );
  }
}
