/**
 * labmouse
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserPrincipal { 
    userId: string;
    name: string;
    role: string;
    uuid?: string;
    active?: number;
    intView?: number;
    lisView?: number;
    cisView?: number;
    addBy?: string;
    addDate?: string;
    updateBy?: string;
    updateDate?: string;
}

