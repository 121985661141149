export const environment = {
  // Environment Name
  env: 'DEV',

  // Is Production Environment?
  production: false,

  // App Name/ID
  appName: 'LABMOUSE',

  // App Description (full name)
  appDescription: 'LabMouse',

  // App Version Number
  appVersion: '8.0.0',

  // App URL Scheme
  appUrlScheme: 'labmouse:',

  // Web URL
  webUrl: 'https://dev.bhstarter.apps.baystatehealth.org',

  // App's REST Service API Root URL
  apiUrl: 'https://dev.internal.apps.baystatehealth.org/labmouse/api',

  // AWS Cognito API Root URL
  awsCognitoUrl: 'https://bh-dev-auth.auth.us-east-1.amazoncognito.com',

  // AWS Cognito Client ID for Auth (Needs client ID specific to app)
  awsCognitoClientId: '7d2qpth3chnv9a41bj3a1s2oe9',

  // Using SSO Authentication
  ssoEnabled: true,

  // App's Uploaded Files Root URL
  filesUrl: 'https://dev.bhstarter.apps.baystatehealth.org',

  // Verlocker API URL
  verlockerUrl: 'https://mobiledev.api.baystatehealth.org/verlocker',

  // BH AppStore Deep Link URL
  appstoreUrl: 'https://mobile.baystatehealth.org/bhstarter/',

  // Reset password link URL
  resetPwdUrl: 'https://baystatehealth.service-now.com/nav_to.do?uri=%2F$pwd_reset.do%3Fsysparm_url%3Dpassword_reset',

  // Change password security questions link URL
  changePwdQuestionsUrl: 'https://baystatehealth.service-now.com/b_sp?id=pwd_reset_enrollment',

  // Set theme preference: user, light, dark
  // 'user' option will allow user to select theme preference (recommended).
  // 'light' option locks app into light mode, and user cannot change it
  // 'dark' option locks app into dark mode, and user cannot change it
  theme: 'user',

  // Store token in local storage to remember user?
  // Patient/Secure Apps should set this to false
  storeToken: true,

  // Allow Biometrics to login
  // This will allow users to login using biometrics (fingerprint, Touch ID or Face ID)
  allowBiometrics: false,

  // Require timeout
  // Patient/Secure Apps will require that timeout is enabled
  // On timeout, user will be bumped
  requireTimeout: false,

  // Timeout threshold
  // Determine the number of seconds when timeout will occur
  // 15 minutes = 900000
  timeoutThreshold: 900000,

  // Enable Multi-language support
  // Shows the Select Language feature on login page, user menu, and account page
  // Update translator service with support languages
  enableMultiLanguageSupport: true,

  //testIdToken: 'eyJraWQiOiI5TjdCVHMyXC9pelBtSTlJNW5lQklKSWhtZEg2XC9JemZiTDFpenRCcXN4OGc9IiwiYWxnIjoiUlMyNTYifQ.eyJhdF9oYXNoIjoiOTBHN01jX3ZtcXROM05nQi1Pemg0ZyIsInN1YiI6ImRjMjAyODMzLTI3OTktNDU0NC05N2NmLWI1ZWYxMjk5ZWRhOSIsImNvZ25pdG86Z3JvdXBzIjpbInVzLWVhc3QtMV9ScGxSOXdDbHBfYmgtZGV2LXNhbWwtc3NvIl0sImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiY3VzdG9tOkdyb3VwcyI6IjAyOGI5YzAxLTJiYzYtNGE0YS1hOTRiLWFkZTg2NjEyNjljMCIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX1JwbFI5d0NscCIsImNvZ25pdG86dXNlcm5hbWUiOiJiaC1kZXYtc2FtbC1zc29fT2xla3NpeS5Lb25vbmVua29AYmF5c3RhdGVoZWFsdGgub3JnIiwiZ2l2ZW5fbmFtZSI6Ik9sZWtzaXkiLCJvcmlnaW5fanRpIjoiY2MzNjhkMjYtMzI4MC00MDI1LWEyZWUtOTRmNDlmN2E0YWNmIiwiYXVkIjoiN2QycXB0aDNjaG52OWE0MWJqM2ExczJvZTkiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJPbGVrc2l5Lktvbm9uZW5rb0BiYXlzdGF0ZWhlYWx0aC5vcmciLCJwcm92aWRlck5hbWUiOiJiaC1kZXYtc2FtbC1zc28iLCJwcm92aWRlclR5cGUiOiJTQU1MIiwiaXNzdWVyIjoiaHR0cHM6XC9cL3N0cy53aW5kb3dzLm5ldFwvZmVmNTY3YmQtYTZjYy00ODYyLThmMGQtYTI4ZmM4YmUyNmU0XC8iLCJwcmltYXJ5IjoidHJ1ZSIsImRhdGVDcmVhdGVkIjoiMTY5OTQ4MDg5NTIzMCJ9XSwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MTg3MzY4NzIsIm5hbWUiOiJLb25vbmVua28sIE9sZWtzaXkiLCJleHAiOjE3MTk0MTYwNDYsImlhdCI6MTcxOTMyOTY0NiwiZmFtaWx5X25hbWUiOiJLb25vbmVua28iLCJqdGkiOiI1ZGJhZTI2YS0wOTY3LTQ4NGEtYTRiNC1kNThlYjdlODAyMmYiLCJlbWFpbCI6Ik9sZWtzaXkuS29ub25lbmtvQGJheXN0YXRlaGVhbHRoLm9yZyIsImN1c3RvbTpFTiI6IkVOMTc2ODIifQ.mnByMAy1ciS1H1Tzdi5bnNBxjBf9tuu_UGbwlAE1UDU_1WJY7Mk8uM0mS8SkYH_QMxfb3hOCXKPKyk3wNWPtXJTFmCUSHkU3DC9QfWa08LRCWJr4wTi40b8i-cZuOMnkRYhZojCGaqsU_dtWKlcb3oyQVjGy1_Wno5-5WQIeVM3kHFSNIgOrhMLhfRhbFPSvLIvOyjQ7UkWBbgGUdVHfbl0CCf-PZW7DtyHMMUqLEbSwgQANpKjg_Fa0G6K4DYzc-dc_ZyJTfSAWCTlPpT9ozPkZ3HDX3TPfpAnrt-OvL3AS_Oo-kFanjkvbDDGbMef8ZRiKjn1snHakYeQLxARX6A'
  //IdToken: ''

};
