<div class="user-icon"
[ngClass]="{
  'small': size === 'small',
  'medium': size === 'medium',
  'large': size === 'large',
  'border': showBorder
 }"
[class]="colorClass">
  <span *ngIf="initials">{{ initials }}</span>
  <ion-icon name="person" *ngIf="!initials"></ion-icon>
</div>
