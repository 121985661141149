import { Routes } from '@angular/router';
import { LoginRouteGuardService } from './services/_core/login-route-guard/login-route-guard.service';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.routes').then((m) => m.routes),
  },
  {
    path: 'login',
    pathMatch: 'full',
    loadComponent: () => import('./pages/login/login.page').then( m => m.LoginPage)
  },
  {
    path: 'help',
    loadComponent: () => import('./pages/_core/help/help.page').then( m => m.HelpPage)
  },
  {
    path: 'share',
    loadComponent: () => import('./pages/_core/share/share.page').then( m => m.SharePage)
  },
  {
    path: 'check-for-updates',
    loadComponent: () => import('./pages/_core/check-for-updates/check-for-updates.page').then( m => m.CheckForUpdatesPage)
  },
  {
    path: 'help-forgot-pwd',
    loadComponent: () => import('./pages/_core/help-forgot-pwd/help-forgot-pwd.page').then( m => m.HelpForgotPwdPage)
  },
  {
    path: 'feedback-modal',
    loadComponent: () => import('./pages/_core/feedback-modal/feedback-modal.page').then( m => m.FeedbackModalPage)
  },
  {
    path: 'cases-view',
    loadComponent: () => import('./pages/cases-view/cases-view.page').then( m => m.CasesViewPage)
  },
  {
    path: 'my-account',
    canActivate: [LoginRouteGuardService],
    loadComponent: () => import('./pages/_core/my-account/my-account.page').then( m => m.MyAccountPage)
  },
  {
    path: 'language-modal',
    loadComponent: () => import('./pages/_core/language-modal/language-modal.page').then( m => m.LanguageModalPage)
  },
  {
    path: 'language-popover',
    loadComponent: () => import('./pages/_core/language-popover/language-popover.page').then( m => m.LanguagePopoverPage)
  },
  {
    path: 'language-translator',
    loadComponent: () => import('./pages/_core/language-translator/language-translator.page').then( m => m.LanguageTranslatorPage)
  },
  {
    path: 'redirect',
    loadComponent: () => import('./pages/redirect/redirect.page').then( m => m.RedirectPage)
  },
  {
    path: 'request-lis-modal',
    loadComponent: () => import('./pages/request-lis-modal/request-lis-modal.page').then( m => m.RequestLisModalPage)
  },
  {
    path: 'request-view-cis',
    loadComponent: () => import('./pages/request-view-cis/request-view-cis.page').then( m => m.RequestViewCisPage)
  },
  {
    path: 'request-view-lis',
    loadComponent: () => import('./pages/request-view-lis/request-view-lis.page').then( m => m.RequestViewLisPage)
  },
  {
    path: 'request-cis-activity-modal',
    loadComponent: () => import('./pages/request-cis-activity-modal/request-cis-activity-modal.page').then( m => m.RequestCisActivityModalPage)
  },
  {
    path: 'manage-users',
    loadComponent: () => import('./pages/manage-users/manage-users.page').then( m => m.ManageUsersPage)
  },
  {
    path: 'manage-admins',
    loadComponent: () => import('./pages/manage-admins/manage-admins.page').then( m => m.ManageAdminsPage)
  },
  {
    path: 'analytics',
    loadComponent: () => import('./pages/analytics/analytics.page').then( m => m.AnalyticsPage)
  },
  {
    path: 'manage-users-add-edit',
    loadComponent: () => import('./pages/manage-users-add-edit/manage-users-add-edit.page').then( m => m.ManageUsersAddEditPage)
  },
  {
    path: 'manage-admins-add-edit',
    loadComponent: () => import('./pages/manage-admins-add-edit/manage-admins-add-edit.page').then( m => m.ManageAdminsAddEditPage)
  },
  {
    path: 'request-edit-modal',
    loadComponent: () => import('./pages/request-edit-modal/request-edit-modal.page').then( m => m.RequestEditModalPage)
  },
  {
    path: 'request-bulk-lis',
    loadComponent: () => import('./pages/request-bulk-lis/request-bulk-lis.page').then( m => m.RequestBulkLisPage)
  },
  {
    path: 'cases-bulk-replay',
    loadComponent: () => import('./pages/cases-bulk-replay/cases-bulk-replay.page').then( m => m.CasesBulkReplayPage)
  },
  {
    path: 'request-cis-bulk-requests',
    loadComponent: () => import('./pages/request-cis-bulk-requests/request-cis-bulk-requests.page').then( m => m.RequestCisBulkRequestsPage)
  }
];
