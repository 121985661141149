import { Injector, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import {
  IonicRouteStrategy,
  provideIonicAngular,
} from '@ionic/angular/standalone';
import { IonicModule } from '@ionic/angular';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import {
  ApiModule,
  Configuration,
  ConfigurationParameters,
} from './app/generated-client/labmouse-api-rest-client';
import { AuthService } from './app/services/_core/auth/auth.service';
import { InterceptorService } from './app/services/_core/interceptor/interceptor.service';
import {
  LucideAngularModule,
  ArrowUpRight,
  File,
  Home,
  Menu,
  UserCheck,
  FileX,
  CircleX,
  GitCompare,
  Link,
  Link2,
  PackageOpen,
  Users,
  UserRoundCog,
  BookText,
  NotebookPen,
  FolderKanban,
  ArrowBigLeft,
  ArrowBigRight,
  NotebookText,
} from 'lucide-angular';
import { SharedModule } from './app/shared/shared.module';

if (environment.production) {
  enableProdMode();

  // Test note
}

// export function apiConfigFactory(authService: AuthService): Configuration {
//   const params: ConfigurationParameters = {
//     basePath: environment.apiUrl,
//     accessToken: authService.getAccessToken.bind(authService),
//   }
//   return new Configuration(params);
// }

// function apiConfig(inj: Injector) {
//   setTimeout( () => {
//     const authService = inj.get(AuthService);
//     console.log('authService main.ts', authService.getAuthUser());

//     const credentials = {'Authoriation' : 'Bearer ' + authService.getAuthUser().token};
//     return new Configuration({basePath: environment.apiUrl, credentials});
//   }, 200)
// }

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // provideHttpClient(),
    provideIonicAngular(),
    importProvidersFrom(IonicStorageModule.forRoot()),
    importProvidersFrom(IonicModule),
    importProvidersFrom(SharedModule),
    importProvidersFrom(
      LucideAngularModule.pick({
        File,
        FileX,
        Home,
        Menu,
        UserCheck,
        CircleX,
        GitCompare,
        PackageOpen,
        Users,
        UserRoundCog,
        BookText,
        NotebookPen,
        FolderKanban,
        ArrowBigLeft,
        ArrowBigRight,
        NotebookText
      })
    ),
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    // {
    //   provide: Configuration,
    //   useFactory: (inj: Injector) => apiConfig(inj), deps: [Injector], multi: false,
    // },
    //importProvidersFrom(ApiModule),
    provideRouter(routes),
  ],
});
