<div [formGroup]="formGroup">
  <div
    class="input-wrapper"
    [ngClass]="{ 'radio-list-wrapper': type === 'radio-list', 'no-border': hideBorder }"
  >
    <div
      class="input-label"
      [ngClass]="{
        'required-label':
          isRequired && !formGroup.controls[formControlName].value
      }"
      *ngIf="type !== 'checkbox' && !hideLabel"
    >
      <span>{{ label | translate }}</span>
      <span class="required-marker" *ngIf="isRequired">*</span>
    </div>
    <!-- Text Input -->
     <div class="flex flex-col">
       <input
         *ngIf="type === 'text' || type === 'phone'"
         [ngClass]="{
           invalid: !formGroup.controls[formControlName].valid,
           pristine: !formGroup.controls[formControlName].pristine,
           untouched: !formGroup.controls[formControlName].untouched
         }"
         [type]="type"
         [formControlName]="formControlName"
         fill="outline"
         placeholder="{{ placeholder | translate }}"
         [autocomplete]="autocomplete"
         [readonly]="readonly"
         [maxlength]="maxlength"
         [pattern]="pattern"
         [class]="width"
         (blur)="blurInput(formControlName)"
         (change)="valueChanged(index, label, $event)"
         (keydown)="handleKeyDown($event, 'txt')"
         (keyup)="handleKeyUp($event, 'txt')"
         (paste)="handlePaste($event, 'txt')"
       />
       <div class="char-counter" *ngIf="maxlength && (type === 'text')">
         Characters left:
         {{ maxlength - formGroup?.controls[formControlName]?.value?.length }}
       </div>
     </div>

    <!-- Date Input -->
    <input
      *ngIf="type === 'date'"
      type="date"
      [ngClass]="{
        invalid: !formGroup.controls[formControlName].valid,
        pristine: !formGroup.controls[formControlName].pristine,
        untouched: !formGroup.controls[formControlName].untouched
      }"
      [formControlName]="formControlName"
      fill="outline"
      placeholder="{{ placeholder | translate }}"
      [autocomplete]="autocomplete"
      [readonly]="readonly"
      [maxlength]="maxlength"
      [pattern]="pattern"
      [class]="width"
      (blur)="blurInput(formControlName)"
      (change)="valueChanged(index, label, $event)"
      (keydown)="handleKeyDown($event, 'txt')"
      (keyup)="handleKeyUp($event, 'txt')"
      (paste)="handlePaste($event, 'txt')"
    />

    <!-- Password Input -->
    <input
      *ngIf="type === 'password'"
      [type]="revealPassword ? 'text' : 'password'"
      [formControlName]="formControlName"
      [ngClass]="{
        invalid: !formGroup.controls[formControlName].valid,
        pristine: !formGroup.controls[formControlName].pristine,
        untouched: !formGroup.controls[formControlName].untouched
      }"
      fill="outline"
      [placeholder]="placeholder ? placeholder : ''"
      [autocomplete]="autocomplete"
      [readonly]="readonly"
      [maxlength]="maxlength"
      [pattern]="pattern"
      [class]="width"
      (blur)="blurInput(formControlName)"
      (change)="valueChanged(index, label, $event)"
      (keydown)="handleKeyDown($event, 'txt')"
      (keyup)="handleKeyUp($event, 'txt')"
      (paste)="handlePaste($event, 'txt')"
    />
    <!-- Reveal password button -->
    <a
      href="javascript:void(0)"
      class="reveal-password-button"
      (click)="revealPassword = !revealPassword"
      *ngIf="type === 'password'"
    >
      <ion-icon name="eye-outline" *ngIf="!revealPassword"></ion-icon>
      <ion-icon name="eye-off-outline" *ngIf="revealPassword"></ion-icon>
    </a>

    <!-- Select -->
    <select
      *ngIf="type === 'select'"
      [formControlName]="formControlName"
      [ngClass]="{
        invalid: !formGroup.controls[formControlName].valid,
        pristine: !formGroup.controls[formControlName].pristine,
        untouched: !formGroup.controls[formControlName].untouched
      }"
      label-placement="stacked"
      fill="outline"
      [autocomplete]="autocomplete"
      [class]="width"
      (blur)="blurInput(formControlName)"
      (change)="valueChanged(index, label, $event)"
    >
      <option value=""></option>
      <option *ngFor="let opt of parsedSelectOptions" [value]="opt?.value">
        {{ opt?.label | translate }}
      </option>
    </select>
    <div class="select-chevron" *ngIf="type === 'select'">
      <ion-icon name="chevron-down-outline"></ion-icon>
    </div>
    <div
      class="select-placeholder"
      *ngIf="
        type === 'select' &&
        placeholder &&
        !formGroup.controls[formControlName].value
      "
    >
      {{ placeholder | translate }}
    </div>

    <!-- Textarea -->
     <div class="flex flex-col w-full">
       <textarea
         fill="outline"
         [ngClass]="{
           invalid: !formGroup.controls[formControlName].valid,
           pristine: !formGroup.controls[formControlName].pristine,
           untouched: !formGroup.controls[formControlName].untouched
         }"
         placeholder="{{ placeholder | translate }}"
         [formControlName]="formControlName"
         [readonly]="readonly"
         [maxlength]="maxlength"
         [pattern]="pattern"
         [class]="width"
         (blur)="blurInput(formControlName)"
         (change)="valueChanged(index, label, $event)"
         (keydown)="handleKeyDown($event, 'txt')"
         (keyup)="handleKeyUp($event, 'txt')"
         (paste)="handlePaste($event, 'txt')"
         *ngIf="type === 'textarea'"
       >
       </textarea>
       <div class="char-counter" *ngIf="maxlength && (type === 'textarea')">
         Characters left:
         {{ maxlength - formGroup?.controls[formControlName]?.value?.length }}
       </div>
     </div>

    <!-- Radio List -->
    <ion-radio-group
      [formControlName]="formControlName"
      mode="md"
      *ngIf="type === 'radio-list'"
    >
      <ion-item *ngFor="let opt of parsedSelectOptions">
        <ion-radio label-placement="end" justify="start" [value]="opt?.value">
          <div>{{ opt?.label | translate }}</div>
          <ion-note *ngIf="opt?.detail">{{ opt?.detail | translate }}</ion-note>
        </ion-radio>
      </ion-item>
    </ion-radio-group>
  </div>

  <!-- Combo Box -->
  <div class="combo-box" *ngIf="type === 'combo-box'">
    <div class="combo-box-select-wrapper">
      <select
        [formControlName]="cbSelectControlName"
        [ngClass]="{
          invalid: !formGroup.controls[formControlName].valid,
          pristine: !formGroup.controls[formControlName].pristine,
          untouched: !formGroup.controls[formControlName].untouched
        }"
        fill="outline"
        [autocomplete]="autocomplete"
        (blur)="blurInput(formControlName)"
        (change)="valueChanged(index, label, $event)"
      >
        <option value="" selected>{{ "All" | translate }}</option>
        <option *ngFor="let opt of parsedSelectOptions" [value]="opt?.value">
          {{ opt?.label | translate }}
        </option>
      </select>
      <div class="select-chevron">
        <ion-icon name="chevron-down-outline"></ion-icon>
      </div>
    </div>

    <div class="combo-box-divider"></div>

    <input
      [ngClass]="{
        invalid: !formGroup.controls[formControlName].valid,
        pristine: !formGroup.controls[formControlName].pristine,
        untouched: !formGroup.controls[formControlName].untouched
      }"
      [formControlName]="formControlName"
      fill="outline"
      [placeholder]="placeholder ? placeholder : ''"
      [autocomplete]="autocomplete"
      [readonly]="readonly"
      (blur)="blurInput(formControlName)"
      (change)="valueChanged(index, label, $event)"
      (keydown)="handleKeyDown($event, 'txt')"
      (keyup)="handleKeyUp($event, 'txt')"
      (paste)="handlePaste($event, 'txt')"
      (keyup.enter)="handleOnEnter($event)"
    />
  </div>

  <!-- Checkbox -->
  <ion-checkbox
    [formControlName]="formControlName"
    labelPlacement="end"
    class="ion-text-wrap"
    mode="md"
    *ngIf="type === 'checkbox'"
  >
  <div class="checkbox-label" *ngIf="!hideLabel">
    {{ label | translate }}
    <div class="helper-text" *ngIf="helperText">
      {{ helperText | translate }}
    </div>
  </div>
  </ion-checkbox>

  <!-- Helper Text -->
  <div class="helper-text" *ngIf="helperText && type !== 'checkbox'">
    {{ helperText | translate }}
  </div>

  <!-- Validation Messages -->
  <div class="validation-errors" *ngIf="validationMessages">
    <ng-container
      *ngFor="let validation of validationMessages[formControlName]"
    >
      <div
        class="ve-message"
        *ngIf="
          formGroup
            .get(formControlName)
            .hasError(validation.type.toLowerCase()) &&
          (formGroup.get(formControlName).dirty ||
            formGroup.get(formControlName).touched ||
            submitAttempted)
        "
      >
        <!-- <ion-icon name="alert-circle-outline"></ion-icon> -->
        {{ validation.message | translate }}
      </div>
    </ng-container>
  </div>
</div>
